import React from 'react';
import { object } from 'prop-types';
import { graphql } from 'gatsby';
import { withPreview } from 'gatsby-source-prismic';
import { langRedirect } from '../utils/lang-helper';
import {
  FullScreenMoodVideoEm001,
  CopyOnlyLeftHandSideEm002,
  StoryOfTheMonthSm001,
  StoriesGridGm001,
  FullWidthSignpostSm002,
  MultipleImagesSignpostModuleSm003,
  LargeSignpostModuleSm004,
  Layout,
  IntroModuleEm003,
  RelatedProductsCarouselRm001,
  VideoModuleEm005,
  MultipleSignpostModuleSm006
} from '../components';
import ContextualEnquiryFormCfm001 from '../components/ContextualEnquiryFormCfm001';
import ContentSnippetEm013 from '../components/ContentSnippetEm013';
import SectionIntroEm004 from '../components/SectionIntroEm004';

class Index extends React.Component {
  static propTypes = {
    data: object.isRequired,
    location: object.isRequired
  };

  constructor(props) {
    super(props);

    this.state = {
      pageLang: 'en-gb'
    };
  }

  componentDidMount() {
    langRedirect(this.state.pageLang);
  }

  render() {
    const {
      props: { data },
      state: { pageLang }
    } = this;

    if (!data?.allPrismicHomepage?.nodes || !data.allPrismicHomepage.nodes.length) {
      return (
        <h1>The data required to preview the page was not found!</h1>
      );
    }

    // Get only the current language
    const sortedData = data.allPrismicHomepage.nodes.filter(obj => {
      return obj.lang === pageLang;
    })?.[0]?.data;

    let key = 0;

    return (
      <div className="PageHolder HomePage">
        <Layout navAlwaysVisible={true} pageLang={pageLang} newsletter={sortedData.newsletter}>
          {sortedData.body.map((componentData) => {
            key++;

            switch (componentData.__typename) {
              case 'PrismicHomepageBodyModuleIdSm001': {
                return <StoryOfTheMonthSm001
                  key={`component-${key}`}
                  prismic={componentData.primary}
                />;
              }
              case 'PrismicHomepageBodyModuleIdSm002': {
                return <FullWidthSignpostSm002
                  key={`component-${key}`}
                  primary={componentData.primary}
                />;
              }
              case 'PrismicHomepageBodyModuleIdSm003': {
                return <MultipleImagesSignpostModuleSm003
                  key={`component-${key}`}
                  prismic={componentData}
                />;
              }
              case 'PrismicHomepageBodyModuleIdEm004': {
                return (
                  <SectionIntroEm004
                    key={`component-${key}`}
                    primary={componentData.primary}
                  />
                );
              }
              case 'PrismicHomepageBodyModuleIdSm004': {
                return (
                  <LargeSignpostModuleSm004
                    key={`component-${key}`}
                    primary={componentData.primary}
                  />
                );
              }
              case 'PrismicHomepageBodyModuleIdEm001': {
                return <FullScreenMoodVideoEm001
                  key={`component-${key}`}
                  primary={componentData}
                />;
              }
              case 'PrismicHomepageBodyModuleIdEm002': {
                return (
                  <CopyOnlyLeftHandSideEm002
                    key={`component-${key}`}
                    primary={componentData.primary}
                  />
                );
              }
              case 'PrismicHomepageBodyModuleIdEm003': {
                return (
                  <IntroModuleEm003
                    key={`component-${key}`}
                    primary={componentData.primary}
                  />
                );
              }
              case 'PrismicHomepageBodyModuleIdGm001': {
                return (
                  <StoriesGridGm001
                    key={`component-${key}`}
                    primary={componentData.primary}
                    items={componentData.items}
                  />
                );
              }
              case 'PrismicHomepageBodyModuleIdCfm001': {
                return (
                  <ContextualEnquiryFormCfm001
                    key={`component-${key}`}
                    primary={componentData.primary}
                  />
                );
              }
              case 'PrismicHomepageBodyModuleIdRm001': {
                return (
                  <RelatedProductsCarouselRm001
                    key={`component-${key}`}
                    primary={componentData.primary}
                    items={componentData.items}
                  />
                );
              }
              case 'PrismicHomepageBodyModuleIdEm005': {
                return (
                  <VideoModuleEm005
                    key={`component-${key}`}
                    primary={componentData.primary}
                  />
                );
              }
              case 'PrismicHomepageBodyModuleIdSm006': {
                return (
                  <MultipleSignpostModuleSm006
                    key={`component-${key}`}
                    primary={componentData.primary}
                    items={componentData.items}
                  />
                );
              }
              case 'PrismicHomepageBodyModuleIdEm013': {
                return (
                  <ContentSnippetEm013
                    key={`component-${key}`}
                    primary={componentData.primary}
                  />
                );
              }
            }
          })}
        </Layout>
      </div>
    );
  }
}

export const IndexBase = Index;

export default withPreview(Index);

// @todo - Be sure to copy it in the other languages
export const query = graphql`
  {
    allPrismicHomepage {
      nodes {
        lang
        data {
          meta_title
          meta_description
          newsletter
          body {
            ... on PrismicHomepageBodyModuleIdEm003 {
              __typename
              primary {
                em3_hide
                em3_section_alignment
                em3_title
                em3_subtitle {
                  text
                }
                em3_copy_top {
                  html
                }
                em3_copy_bottom {
                  html
                  text
                }
                em3_bg_image {
                  fluid(maxWidth: 1024) {
                    src
                    srcSet
                    aspectRatio
                    sizes
                  }

                }
                em3_main_image {
                  fluid {
                    src
                    srcSet
                    aspectRatio
                    sizes
                  }
                }
                em3_video_link {
                  embed_url
                }
              }
            }
            ... on PrismicHomepageBodyModuleIdEm001 {
              __typename
              primary {
                em1_video_link
              }
            }
            ... on PrismicHomepageBodyModuleIdEm002 {
              __typename
              primary {
                em2_hide
                em2_copy {
                  html
                }
              }
            }
            ... on PrismicHomepageBodyModuleIdSm001 {
              __typename
              primary {
                sm1_hide
                sm1_title
                sm1_image {
                  fluid {
                    src
                    srcSet
                    aspectRatio
                    sizes
                  }
                }
                sm1_link_name
                sm1_link_story {
                  uid
                }
                sm1_link_rel
                sm1_video_link {
                  embed_url
                }
                sm1_section_title
              }
            }
            ... on PrismicHomepageBodyModuleIdGm001 {
              __typename
              primary {
                gm1_hide
                gm1_section_title
                gm1_enable_load_more
              }
              items {
                gm1_latest_stories_image {
                  fluid {
                    src
                    srcSet
                    aspectRatio
                    sizes
                  }
                }
                gm1_latest_stories_title {
                  html
                  text
                }
                gm1_latest_stories_text {
                  html
                  text
                }
                gm1_latest_stories_link_name {
                  html
                  text
                }
                gm1_latest_stories_link {
                  uid
                }
                gm1_latest_stories_link_rel
              }
            }
            ... on PrismicHomepageBodyModuleIdSm002 {
              __typename
              primary {
                sm2_hide
                sm2_title
                sm2_description {
                  html
                }
                sm2_link_name
                sm2_link {
                  uid
                }
                sm2_link_rel
                sm2_image {
                  fluid(maxWidth: 2880) {
                    src
                    srcSet
                    aspectRatio
                    sizes
                  }
                }
              }
            }
            ... on PrismicHomepageBodyModuleIdSm003 {
              __typename
              primary {
                sm3_hide
                sm3_title
                sm3_link_name
                sm3_link {
                  link_type
                  url
                  target
                }
                sm3_link_rel
                sm3_description {
                  html
                  text
                }
              }
              items {
                sm3_image_name
                sm3_image {
                  fluid(maxWidth: 1000) {
                    src
                    srcSet
                    aspectRatio
                    sizes
                  }
                }
                sm3_image_link {
                  uid
                }
                sm3_image_link_rel
              }
            }
            ... on PrismicHomepageBodyModuleIdEm004
            {
              __typename
              primary {
                em4_hide
                em4_section_alignment
                em4_small_title
                em4_big_title
                em4_copy {
                  html
                }
              }
            }
            ... on PrismicHomepageBodyModuleIdSm004 {
              __typename
              primary {
                sm4_hide
                sm4_section_alignment
                sm4_section_rotate
                sm4_title
                sm4_description {
                  html
                }
                sm4_link_name
                sm4_link {
                  uid
                }
                sm4_link_rel
                sm4_image {
                  fluid(maxWidth: 1920) {
                    src
                    srcSet
                    aspectRatio
                    sizes
                  }
                }
              }
            }
            ... on PrismicHomepageBodyModuleIdCfm001 {
              id
              primary {
                cfm1_hide
                cfm1_title
                cfm1_copy {
                  html
                }
                cfm1_form_type
                cfm1_privacy_note {
                  html
                }
                cfm1_success_title
                cfm1_success_copy {
                  html
                }
              }
            }
            ... on PrismicHomepageBodyModuleIdRm001 {
              primary {
                rm1_hide
                rm1_title {
                  text
                }
              }
              items {
                rm1_related_product {
                  document {
                    __typename
                    ... on PrismicProduct {
                      uid
                      data {
                        title {
                          text
                        }
                        lister_title
                        lister_description {
                          html
                        }
                        lister_image {
                          fluid {
                            src
                            srcSet
                            aspectRatio
                            sizes
                          }
                        }
                        lister_video_link {
                          embed_url
                        }
                      }
                    }
                    ... on PrismicLifestyle {
                      uid
                      data {
                        title {
                          text
                        }
                        lister_title
                        lister_description {
                          html
                        }
                        lister_image {
                          fluid {
                            src
                            srcSet
                            aspectRatio
                            sizes
                          }
                        }
                        lister_video_link {
                          embed_url
                        }
                      }
                    }
                  }
                }
              }
            }
            ... on PrismicHomepageBodyModuleIdEm005 {
              primary {
                em5_hide
                em5_section_alignment
                em5_video_thumbnail {
                  fluid(maxWidth: 2880) {
                    src
                    srcSet
                    aspectRatio
                    sizes
                  }
                }
                em5_video_link
                em5_video_behavior
                em5_description {
                  html
                }
                em5_bottom_title
                em5_bottom_description {
                  html
                }
              }
            }
            ... on PrismicHomepageBodyModuleIdSm006 {
              primary {
                sm6_hide
              }
              items {
                sm6_link_name
                sm6_link_description
                sm6_link {
                  uid
                }
                sm6_link_rel
                sm6_image {
                  fluid {
                    src
                    srcSet
                    aspectRatio
                    sizes
                  }
                }
              }
            }
            ... on PrismicHomepageBodyModuleIdEm013 {
              primary {
                em13_hide
                em13_section_style
                em13_title {
                  text
                }
                em13_copy {
                  html
                }
                em13_highlight_background
                em13_image_video_thumb {
                  fluid {
                    src
                    srcSet
                    aspectRatio
                    sizes
                  }
                }
                em13_main_video_link {
                  embed_url
                }
                em13_supporting_image {
                  fluid {
                    src
                    srcSet
                    aspectRatio
                    sizes
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;
