import { IndexBase as IndexBaseOriginal } from './index';
import { withPreview } from 'gatsby-source-prismic';
import { graphql } from 'gatsby';

export class Index extends IndexBaseOriginal {
  constructor(props) {
    super(props);

    this.state = {
      pageLang: 'ja-jp'
    };
  }
}

export default withPreview(Index);

// export { query } from "./index"; // @todo check why it`s not working
export const query = graphql`
  {
    allPrismicHomepage {
      nodes {
        lang
        data {
          meta_title
          meta_description
          newsletter
          body {
            ... on PrismicHomepageBodyModuleIdEm003 {
              __typename
              primary {
                em3_hide
                em3_section_alignment
                em3_title
                em3_subtitle {
                  text
                }
                em3_copy_top {
                  html
                }
                em3_copy_bottom {
                  html
                  text
                }
                em3_bg_image {
                  fluid(maxWidth: 2880) {
                    src
                    srcSet
                    aspectRatio
                    sizes
                  }

                }
                em3_main_image {
                  fluid {
                    src
                    srcSet
                    aspectRatio
                    sizes
                  }
                }
                em3_video_link {
                  embed_url
                }
              }
            }
            ... on PrismicHomepageBodyModuleIdEm001 {
              __typename
              primary {
                em1_video_link
              }
            }
            ... on PrismicHomepageBodyModuleIdEm002 {
              __typename
              primary {
                em2_hide
                em2_copy {
                  html
                }
              }
            }
            ... on PrismicHomepageBodyModuleIdSm001 {
              __typename
              primary {
                sm1_hide
                sm1_title
                sm1_image {
                  fluid {
                    src
                    srcSet
                    aspectRatio
                    sizes
                  }
                }
                sm1_link_name
                sm1_link_story {
                  uid
                }
                sm1_link_rel
                sm1_video_link {
                  embed_url
                }
                sm1_section_title
              }
            }
            ... on PrismicHomepageBodyModuleIdGm001 {
              __typename
              primary {
                gm1_hide
                gm1_section_title
                gm1_enable_load_more
              }
              items {
                gm1_latest_stories_image {
                  fluid {
                    src
                    srcSet
                    aspectRatio
                    sizes
                  }
                }
                gm1_latest_stories_title {
                  html
                  text
                }
                gm1_latest_stories_text {
                  html
                  text
                }
                gm1_latest_stories_link_name {
                  html
                  text
                }
                gm1_latest_stories_link {
                  uid
                }
                gm1_latest_stories_link_rel
              }
            }
            ... on PrismicHomepageBodyModuleIdSm002 {
              __typename
              primary {
                sm2_hide
                sm2_title
                sm2_description {
                  html
                }
                sm2_link_name
                sm2_link {
                  uid
                }
                sm2_link_rel
                sm2_image {
                  fluid(maxWidth: 2880) {
                    src
                    srcSet
                    aspectRatio
                    sizes
                  }
                }
              }
            }
            ... on PrismicHomepageBodyModuleIdSm003 {
              __typename
              primary {
                sm3_hide
                sm3_title
                sm3_link_name
                sm3_link {
                  link_type
                  url
                  target
                }
                sm3_link_rel
                sm3_description {
                  html
                  text
                }
              }
              items {
                sm3_image_name
                sm3_image {
                  fluid(maxWidth: 1000) {
                    src
                    srcSet
                    aspectRatio
                    sizes
                  }
                }
                sm3_image_link {
                  uid
                }
                sm3_image_link_rel
              }
            }
            ... on PrismicHomepageBodyModuleIdSm004 {
              __typename
              primary {
                sm4_hide
                sm4_section_alignment
                sm4_section_rotate
                sm4_title
                sm4_description {
                  html
                }
                sm4_link_name
                sm4_link {
                  uid
                }
                sm4_link_rel
                sm4_image {
                  fluid(maxWidth: 1920) {
                    src
                    srcSet
                    aspectRatio
                    sizes
                  }
                }
              }
            }
            ... on PrismicHomepageBodyModuleIdCfm001 {
              id
              primary {
                cfm1_hide
                cfm1_title
                cfm1_copy {
                  html
                }
                cfm1_form_type
                cfm1_privacy_note {
                  html
                }
                cfm1_success_title
                cfm1_success_copy {
                  html
                }
              }
            }
            ... on PrismicHomepageBodyModuleIdRm001 {
              primary {
                rm1_hide
                rm1_title {
                  text
                }
              }
              items {
                rm1_related_product {
                  document {
                    __typename
                    ... on PrismicProduct {
                      uid
                      data {
                        title {
                          text
                        }
                        lister_title
                        lister_description {
                          html
                        }
                        lister_image {
                          fluid {
                            src
                            srcSet
                            aspectRatio
                            sizes
                          }
                        }
                        lister_video_link {
                          embed_url
                        }
                      }
                    }
                    ... on PrismicLifestyle {
                      uid
                      data {
                        title {
                          text
                        }
                        lister_title
                        lister_description {
                          html
                        }
                        lister_image {
                          fluid {
                            src
                            srcSet
                            aspectRatio
                            sizes
                          }
                        }
                        lister_video_link {
                          embed_url
                        }
                      }
                    }
                  }
                }
              }
            }
            ... on PrismicHomepageBodyModuleIdEm005 {
              primary {
                em5_hide
                em5_section_alignment
                em5_video_thumbnail {
                  fluid(maxWidth: 2880) {
                    src
                    srcSet
                    aspectRatio
                    sizes
                  }
                }
                em5_video_link
                em5_video_behavior
                em5_description {
                  html
                }
                em5_bottom_title
                em5_bottom_description {
                  html
                }
              }
            }
            ... on PrismicHomepageBodyModuleIdSm006 {
              primary {
                sm6_hide
              }
              items {
                sm6_link_name
                sm6_link_description
                sm6_link {
                  uid
                }
                sm6_link_rel
                sm6_image {
                  fluid {
                    src
                    srcSet
                    aspectRatio
                    sizes
                  }
                }
              }
            }
            ... on PrismicHomepageBodyModuleIdEm013 {
              primary {
                em13_hide
                em13_section_style
                em13_title {
                  text
                }
                em13_copy {
                  html
                }
                em13_highlight_background
                em13_image_video_thumb {
                  fluid {
                    src
                    srcSet
                    aspectRatio
                    sizes
                  }
                }
                em13_supporting_image {
                  fluid {
                    src
                    srcSet
                    aspectRatio
                    sizes
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;
